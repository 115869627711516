import React from "react";
import Header from "./Comp/Header";
import TopNav from "./TopNav";
import Footer from "./Footer";
import img0 from "../assets/flowers-crop-4.webp";
import img1 from "../assets/psychology-therapy.jpg";
import img2 from "../assets/couple-wearing-masks-3.jpg";
import img3 from "../assets/vector-collection.jpg";
import img4 from "../assets/pngtree3.png";
import img5 from "../assets/business-illustrations.png";
import img6 from "../assets/start-speech-therapy.png";
import img7 from "../assets/featured.jpg";
import img8 from "../assets/business-people.jpg";
import img9 from "../assets/ethical-dilemma.avif";
import img10 from "../assets/flat-mental-health.avif";
import img11 from "../assets/creative-speech-therapy.avif";
import img12 from "../assets/wheelchair.jpg";
import img13 from "../assets/meditation-work.jpg";
import img14 from "../assets/cognitive-behavioral-therapy.webp";
import img15 from "../assets/drawings_Kensington_11_EMDR-big-1.png";
import InfoSection from "./Comp/InfoSection";

function Services() {
  const cardsData = [
    {
      id: 1,
      imgSrc: img1,
      title: "Psychotherapy",
      description:
        "Psychotherapy Personalized therapy to address mental health issues.",
      url: "/psychotherapy",
    },
    {
      id: 2,
      imgSrc: img2,
      title: "Couple Therapy",
      description:
        "Helping couples improve communication and resolve conflicts.",
      url: "/coupletherapy",
    },
    {
      id: 3,
      imgSrc: img3,
      title: "Interpersonal Therapy",
      description: "Enhancing social functioning and relationships.",
      url: "/interpersonal",
    },
    {
      id: 4,
      imgSrc: img4,
      title: "Counseling",
      description:
        "Providing guidance and support for various life challenges.",
      url: "/counseling",
    },
    {
      id: 5,
      imgSrc: img5,
      title: "Career Counseling",
      description: "Assisting with career decisions and transitions.",
      url: "/career",
    },
    {
      id: 6,
      imgSrc: img6,
      title: "Speech Therapy",
      description: "Addressing speech and communication issues.",
      url: "/speechtherapy",
    },
    {
      id: 7,
      imgSrc: img7,
      title: "Dialectical Behavior Therapy (DBT)",
      description:
        "Managing emotions and improving interpersonal effectiveness.",
      url: "/dbt",
    },
    {
      id: 8,
      imgSrc: img8,
      title: "Stress Management",
      description:
        "Techniques to reduce stress, improve well-being, and enhance mental resilience.",
      url: "/stressmanage",
    },
    {
      id: 9,
      imgSrc: img9,
      title: "Anger Management",
      description: "Strategies to control and express anger healthily.",
      url: "/angermanage",
    },
    {
      id: 10,
      imgSrc: img10,
      title: "Disorder Treatments",
      description:
        "Specialized treatment plans for various mental health disorders.",
      url: "/disorder",
    },
    {
      id: 11,
      imgSrc: img11,
      title: "Speech Issues",
      description: "Therapy to improve speech and language skills.",
      url: "/speechissues",
    },
    {
      id: 12,
      imgSrc: img12,
      title: "Special Needs Issues",
      description:
        "Providing Individual Care and Support to nurture the Potential of each Child.",
      url: "/specialneeds",
    },
    {
      id: 13,
      imgSrc: img13,
      title: "Relaxation Techniques",
      description:
        "Providing techniques for your path to inner calm and resilience.",
      url: "/relaxationtechniques",
    },
    {
      id: 14,
      imgSrc: img14,
      title: "Cognitive Behavior Therapy (CBT)",
      description:
        "Helps individuals identify and change negative thought patterns and behaviors.",
      url: "/cbt",
    },
    {
      id: 15,
      imgSrc: img15,
      title: "Eye Movement Desensitization and Reprocessing (EMDR)",
      description:
        "Helps individuals alleviate distress from traumatic memories through guided eye movements.",
      url: "/emdr",
    },
  ];
  return (
    <>
      <TopNav />
      <main>
        <Header leftTitle="Our Services" breadcrumbTitle="Services" />
        <section>
          <div className="flex items-center justify-center space-x-4 relative -my-16 md:-my-4 lg:-my-0">
            {/* Image on the left, fixed in place */}
            <div className="relative w-full max-w-2xl top-0 left-0 z-0">
              <img
                src={img0}
                alt="Background"
                className="w-full h-auto rounded-lg object-cover"
              />
            </div>

            {/* Text in the center */}
            <div className="w-full lg:w-1/3 text-center relative z-10 mt-20  pr-5">
              <h2 className="text-lg md:text-2xl lg:text-3xl py-1 text-[#4db953] font-sans font rounded-lg px-2 w-full md:w-3/4 mb-2">
                Therapies & Treatments
              </h2>
              <p className="text-sm md:text-lg font-light text-gray-600 w-full md:w-3/4">
                Explore Our Comprehensive Therapy Services for Every Need
              </p>
            </div>
          </div>
        </section>
        <section>
          <div
            id="expComp"
            className="py-14 my-6 px-6 sm:px-8 md:px-16 lg:px-28 2xl:px-36 mt-20 md:mt-10 lg:mt-5"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 md:gap-16 py-5">
              {cardsData.map((card) => (
                <a
                  key={card.id}
                  href={card.url}
                  className="flex flex-col h-full shadow-md hover:shadow-lg hover:shadow-[#e0ebe4] p-8 items-center justify-center self-stretch rounded-2xl bg-[#efecf1] transform transition duration-500 hover:scale-105 ease-in-out  relative"
                >
                  {/* Outer div for border around the image */}
                  <div className="w-28 h-28 rounded-full border-2 border-[#afeaa5] flex items-center justify-center -mt-20 mb-4">
                    <div className="w-24 h-24 rounded-full overflow-hidden">
                      <img
                        src={card.imgSrc}
                        className="w-full h-full object-cover"
                        alt={card.title}
                      />
                    </div>
                  </div>
                  <p className="text-gray-400 text-xl font-bold pt-3 text-center">
                    {card.number}
                  </p>
                  <p className="font-semibold text-lg text-stone-800 pt-3 text-center">
                    {card.title}
                  </p>
                  <p className="text-base text-stone-600 pt-3 text-center">
                    {card.description}
                  </p>
                </a>
              ))}
            </div>
          </div>
        </section>

        <InfoSection />
      </main>
      <Footer />
    </>
  );
}

export default Services;
