import React from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import img2 from "../assets/mamabout.jpeg";
import img1 from "../assets/business-desktop-with-office-elements.jpg";
import img3 from "../assets/story.png";
import img4 from "../assets/values-beliefs.png";
import img5 from "../assets/illustration-of-an-open-book.webp";
import InfoSection from "./Comp/InfoSection";

const About = () => {
  return (
    <>
      <TopNav />
      <main>
        <div
          className="relative pt-16 pb-32 mt-10 flex content-center items-center h-[400px] md:h-[600px] justify-center"
          style={{
            minHeight: "75vh",
            backgroundImage: `url(${img1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
          }}
        >
          <div className="absolute top-0 w-full h-full bg-black opacity-60"></div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div>
                  <h1 className="text-white font-semibold  text-2xl sm:text-3xl md:text-6xl">
                    Dr. Habiba
                  </h1>
                  <h2 className="mt-4 text-white font-semibold text-xl sm:text-2xl md:text-3xl">
                    Life Coach
                  </h2>
                  <p className="mt-4 text-lg sm:text-xl md:text-2xl text-gray-300">
                    Providing Innovative and Effective Mental Health Solutions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section>
          <div className="container mx-auto px-4 pt-4 mt-10">
            <div className="items-center flex flex-wrap md:flex-row-reverse">
              <div className="w-full md:w-2/3 lg:w-5/12  ml-auto mr-auto px-2">
                <img
                  src={img2}
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg lg:h-[600px] "
                />
              </div>
              <div className="w-full lg:w-5/12 ml-auto mr-auto leading-relaxed px-2 md:px-6 pr-2 md:pr-6">
                <div className="xl:pr-8 h-full">
                  <div className="p-3 text-center inline-flex items-center justify-center w-16 h-16 mt-20 lg:mt-0 mb-6  shadow-lg rounded-full bg-[#9ccdb1]">
                    <img src={img5} alt="icon" className="h-12 w-16" />
                  </div>
                  <h3 className="text-xl md:text-3xl font-semibold ">
                    My Approach & Philosophy
                  </h3>
                  <p className="mt-4 text-base md:text-lg leading-relaxed text-gray-600">
                    I focus on personalized, client-centered care, tailoring
                    therapy to meet individual needs. My integrative approach
                    combines various techniques from my extensive training to
                    provide comprehensive treatment.
                  </p>
                  <p className="mt-4text-base md:text-lg leading-relaxed text-gray-600">
                    Committed to continuous learning, I stay updated with the
                    latest advancements to offer the best care. Therapy is a
                    collaborative process, empowering clients to achieve their
                    mental health goals.
                  </p>
                  <p className="mt-4 text-base md:text-lg leading-relaxed text-gray-600">
                    Leveraging the latest technology, I enhance therapy
                    accessibility and effectiveness. Compassion and empathy are
                    at the core of my practice, ensuring a supportive
                    environment.
                  </p>
                  <p className="mt-4 text-base md:text-lg leading-relaxed text-gray-600">
                    Committed to continuous learning, I stay updated with the
                    latest advancements to offer the best care. Therapy is a
                    collaborative process, empowering clients to achieve their
                    mental health goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mx-auto px-4 py-14">
            <div className="flex flex-wrap items-start py-8">
              <div className="w-full lg:w-5/12 ml-auto mr-auto flex flex-col h-full px-2 md:px-6">
                <div className="xl:pr-8 h-full ">
                  <div className="p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6  shadow-lg rounded-full bg-[#9ccdb1]">
                    <img src={img3} alt="icon" />
                  </div>
                  <h3 className="text-xl md:text-3xl  font-semibold">
                    My Story
                  </h3>
                  <p className="mt-4 text-base md:text-lg leading-relaxed text-gray-600">
                    Welcome! I’m Dr. Habiba Nazim, the founder of Mindful
                    Connection Hub. With decade of experience in the field of
                    psychology, I am specialized in a variety of therapies
                    including psychotherapy, couple therapy, interpersonal
                    therapy, and more.
                  </p>
                  <p className="mt-4 text-base md:text-lg leading-relaxed text-gray-600">
                    I hold an M.Phil in Clinical Psychology and currently
                    pursuing a PhD in Clinical Psychology. I have certifications
                    in various fields such as EMDR, DBT, Speech Therapy, and an
                    Advanced Diploma in Clinical Psychology.
                  </p>
                  <p className="mt-4 mb-4 text-base md:text-lg leading-relaxed text-gray-600">
                    My approach integrates the latest in IT to enhance
                    therapeutic outcomes. My mission is to help individuals and
                    couples navigate their mental health journeys with
                    compassion and cutting-edge techniques.
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-5/12 ml-auto mr-auto  flex flex-col h-full px-2 md:px-6">
                <div className="xl:pr-8 h-full">
                  <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 mt-16 lg:mt-0 shadow-lg rounded-full bg-[#9ccdb1]">
                    <img src={img4} alt="icon" />
                  </div>
                  <h3 className="text-xl md:text-3xl font-semibold">
                    My Values & Beliefs
                  </h3>
                  <h4 className="mt-4 text-base md:text-lg leading-relaxed font-semibold text-gray-600">
                    Commitment to Excellence in Mental Health Care
                  </h4>
                  <p className="mt-1 text-base md:text-lg leading-relaxed text-gray-600">
                    With over a decade of experience in psychology, I am
                    dedicated to providing top-notch mental health services. My
                    extensive education and numerous certifications reflect my
                    commitment to staying at the forefront of the field,
                    ensuring that my clients receive the most effective and
                    up-to-date therapeutic techniques.
                  </p>
                  <h4 className="mt-4 text-base md:text-lg leading-relaxed font-semibold text-gray-600">
                    Compassion and Empathy
                  </h4>
                  <p className="mt-1 text-base md:text-lg leading-relaxed text-gray-600">
                    Understanding and compassion are at the heart of my
                    practice. I believe in creating a safe and supportive
                    environment where clients feel heard and valued. This
                    empathy-driven approach is crucial for effective therapy and
                    positive mental health outcomes.
                  </p>
                  <h4 className="mt-4 text-base md:text-lg leading-relaxed font-semibold text-gray-600">
                    Innovation and Integration of Technology
                  </h4>
                  <p className="mt-1 mb-4text-base md:text-lg leading-relaxed text-gray-600">
                    Incorporating the latest advancements in IT, I strive to
                    enhance the therapeutic process. By leveraging technology, I
                    aim to provide innovative solutions that make therapy more
                    accessible and effective for my clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <InfoSection />
      </main>
      <Footer />
    </>
  );
};

export default About;
