import React from "react";

function Text({ img, heading, children, isReversed }) {
  return (
    <>
      <div
        className={`flex flex-col ${
          isReversed ? "md:flex-row-reverse" : "md:flex-row"
        } h-auto px-4 md:px-32 py-14 gap-y-10 md:gap-x-20`}
      >
        <div className="flex flex-col rounded-xl w-full md:w-1/2">
          <img
            className="object-fill rounded-xl  shadow-xl shadow-[#f7ece1]"
            src={img}
            alt="image"
          />
        </div>
        <div className="flex flex-col w-full md:w-1/2 py-10">
          <div className="font-base text-dark">
            <h3 className="text-lg">{heading}</h3>
            <div className="text-sm text-gray-600">
              {children} {/* Allowing for multiple paragraphs and spans */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Text;
