import React from "react";
import { useLocation } from "react-router-dom";
import img from "../assets/Logoo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function TopNav() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const location = useLocation(); // Get current location

  // Function to check if the link is active
  const isActive = (path) =>
    location.pathname === path || location.pathname.startsWith(`${path}/`);

  return (
    <nav className="fixed top-0 z-50 w-full bg-white shadow-lg flex flex-wrap items-center justify-between px-2">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <a
            className="text-gray-800 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            href="/"
          >
            <img src={img} alt="Logo" className="h-12 w-auto inline-block" />
          </a>

          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <FontAwesomeIcon icon={faBars} className="text-gray-800" />
          </button>
        </div>

        <div
          className={`lg:flex flex-grow items-center ml-auto ${
            navbarOpen ? "block rounded shadow-lg" : "hidden"
          }`}
          id="example-navbar"
          onMouseLeave={() => setNavbarOpen(false)} // Close menu on mouse leave
        >
          <ul className="flex flex-col lg:flex-row items-center list-none lg:ml-auto">
            {[
              "/",
              "/about",
              "/services",
              "/blogs",
              "/events",
              "/contactus",
            ].map((path, index) => {
              const pageNames = [
                "Home",
                "About",
                "Services",
                "Blogs",
                "Events",
                "Contact Us",
              ];
              return (
                <li className="flex items-center" key={index}>
                  <a
                    href={path}
                    className={`px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold ${
                      isActive(path)
                        ? "text-[#71a371]"
                        : "text-gray-800 hover:text-[#71a371]"
                    }`}
                  >
                    {pageNames[index]}
                  </a>
                </li>
              );
            })}

            {/* Make Appointment Button */}
            <li className="flex items-center">
              <a href="/booknow">
                <button
                  className={`bg-gradient-to-r from-[#71a371] to-[#61987a] hover:bg-emerald-700 mx-4 text-white font-semibold py-2 px-4 rounded-full text-sm ${
                    navbarOpen ? "mt-4" : ""
                  } ${navbarOpen ? "mb-4" : ""}`} // Add margin-bottom only when navbar is open
                >
                  Make Appointment
                </button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
