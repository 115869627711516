/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import {
  FaMailBulk,
  FaMapMarkerAlt,
  FaRegClock,
  FaPhoneAlt,
} from "react-icons/fa";
import Header from "./Comp/Header";

function Contact() {
  return (
    <>
      <TopNav />
      <main>
        <Header leftTitle="Contact" breadcrumbTitle="Contact us" />

        <section>
          <div className="min-h-screen flex items-center justify-center p-6 my-12 2xl:my-0">
            <div className="w-full max-w-5xl p-2 md:p-4 lg:p-6 bg-[#f7fefa5b] shadow-md rounded-lg grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Form Section */}
              <div className="p-8">
                <h2 className="text-3xl font-semibold mb-6">Send a Message</h2>
                <form className="space-y-4">
                  <div>
                    <input
                      type="text"
                      placeholder="Name"
                      className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-[#627c45]"
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      placeholder="Email Address"
                      className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-[#627c45]"
                    />
                  </div>
                  <div>
                    <textarea
                      placeholder="Message"
                      rows="4"
                      className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-[#627c45]"
                    />
                  </div>
                  <button
                    className="bg-[#61987a] text-white active:bg-lime-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-2xl outline-none focus:outline-none mt-10 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Submit
                  </button>
                </form>
              </div>

              {/* Contact Information Section */}
              <div className="p-8 bg-[#f1fdf1] text-stone-600 rounded-lg shadow-md md:mt-0 mt-6">
                <h3 className="text-lg text-stone-600 font-semibold flex items-center">
                  <span className="text-[#61987a] text-lg mr-2">
                    <FaPhoneAlt />
                  </span>
                  Call
                </h3>
                <div className="mb-6">
                061-6510016
                </div>

                <h3 className="text-lg text-stone-600 font-semibold flex items-center">
                  <span className="text-[#61987a] text-lg mr-2">
                    <FaMailBulk />
                  </span>
                  Email
                </h3>
                <div className="mb-6">
                  <a
                    href="mailto:info@mindfulch.com"
                    className="text-stone-500 hover:underline"
                  >
                    info@mindfulch.com
                  </a>
                </div>

                <h3 className="text-lg text-stone-600 font-semibold flex items-center">
                  <span className="text-[#61987a] text-lg mr-2">
                    <FaMapMarkerAlt />
                  </span>
                  Address
                </h3>
                <p className="mb-6 text-stone-500">
                  Model Town, Haseeb Street, 42-43 B,
                  <br />
                  Multan, Pakistan.
                </p>

                <h3 className="text-lg font-semibold text-stone-600 flex items-center">
                  {" "}
                  <span className="text-[#61987a] text-lg mr-2">
                    <FaRegClock />
                  </span>
                  Hours
                </h3>
                <p className="text-stone-500">Mon – Fri: 3pm – 9pm</p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div id="direction" classname="google-map-container">
            <iframe
              width="100%"
              height="450"
              frameborder="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3216.540169040174!2d71.49348654902591!3d30.24473063565357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x393b35d1895d240f%3A0xf4f27e5b3de72dbb!2sMindfulConnection%20Hub!5e0!3m2!1sen!2s!4v1699195768584!5m2!1sen!2s"
              allowfullscreen
            ></iframe>
          </div>
        </section>

        <section>
          <div className="w-full flex flex-col md:flex-row items-center">
            {/* Schedule Appointment Section */}
            <div className="flex flex-col items-center bg-[#70b96d] hover:bg-[#5b9b59] w-full px-6 py-10 space-y-6 md:space-y-0 md:space-x-6 min-h-[300px]">
              {/* Schedule Your Appointment Today */}
              <div className="flex flex-col items-center space-y-2 text-center">
                <span className="text-white text-3xl">
                  <FaRegClock />
                </span>
                <div className="text-white">
                  <h3 className="text-xl sm:text-2xl md:text-3xl font-semibold leading-relaxed mb-4 mt-4">
                    Schedule Your Meeting!
                  </h3>
                  <p className="text-normal leading-relaxed text-center mb-4">
                    Mon-Fri: 5PM - 8PM
                    <br />
                    Online Session: 45 minutes
                  </p>
                </div>
                {/* Make Appointment */}
                <a
                  href="/booknow"
                  className="bg-[#e7f2ec] text-sm items-center font-semibold py-2 px-4 rounded-full shadow-md hover:bg-gray-200 transition"
                >
                  Make Appointment
                </a>
              </div>
            </div>

            {/* Visit Clinic Section */}
            <div className="flex flex-col items-center bg-[#61987a] hover:bg-[#507a63] w-full px-6 py-10 space-y-6 md:space-y-0 md:space-x-6  min-h-[300px]">
              {/* Visit Clinic */}
              <div className="flex flex-col items-center space-y-2 text-center">
                <span className="text-white text-3xl">
                  <FaMapMarkerAlt />
                </span>
                <div className="text-white">
                  <h3 className="text-xl sm:text-2xl md:text-3xl font-semibold leading-relaxed mb-4 mt-4">
                    Visit Our Clinic
                  </h3>
                  <p className="text-normal leading-relaxed text-center mb-4">
                    Model Town, Haseeb Street, 42-43 B,
                    <br />
                    Multan, Pakistan.
                  </p>
                </div>
                {/* Get Direction Button */}
                <a
                  href="#direction"
                  className="bg-[#e7f2ec] items-center text-sm font-semibold py-2 px-4 rounded-full shadow-md hover:bg-gray-200 transition"
                >
                  Get Direction
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}

export default Contact;
