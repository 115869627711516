import React from "react";
import Header from "../Comp/Header";
import defaultImage from "../../assets/womans-head.jpg";
import ArticleCards from "../Comp/ArticleCards";
import imgx from "../../assets/360_F_82442519_3dyKdM0EXzhBO027kDLkeJHuPLeplLlf.jpg";
import imgy from "../../assets/yoga-6723315_640.jpg";
import imgz from "../../assets/1695048915502.jpg";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Pagination from "../Comp/Pagination";
import BrowseButton from "../Comp/BrowseButton";
import Text from "../Comp/Text";
import img1 from "../../assets/imageforentry13-ft9.webp";
import img2 from "../../assets/Relationship-issues.jpg";
import img3 from "../../assets/Blog+Post+Banner_Q&A_about+Couples-Counselling.jpg";

function CoupleTherapy() {
  const cards = [
    {
      imgSrc: imgx,
      title: "Career Counseling",
      description:
        "Career counseling is a professional service that helps individuals understand their strengths...",
      link: "/career",
    },
    {
      imgSrc: imgy,
      title: "Stress Management",
      description:
        "Stress is a natural physical and emotional response to challenges, demands, or threats...",
      link: "/stressmanage",
    },
    {
      imgSrc: imgz,
      title: "Relaxation Techniques",
      description:
        "Relaxation techniques in the therapeutic process refer to a set of practices and strategies used...",
      link: "/relaxationtechniques",
    },
  ];
  const cardData = [
    {
      img: img1,
      text: (
        <>
          <h1 className="text-3xl font-semibold mb-4 text-black leading-relaxed">
            Couple Therapy?
          </h1>
          <p className="text-base text-black leading-relaxed">
            Couple therapy, also known as couples counseling or marriage
            therapy, is a type of psychotherapy aimed at helping couples improve
            their relationship. This therapy can help partners understand and
            resolve conflicts, enhance their relationship, and address various
            issues that may be causing distress in their partnership.
          </p>
          <p className="text-base text-black leading-relaxed">
            By not limiting therapy to a single approach, therapists can draw on
            the strengths of various modalities to address a wide range of
            issues, from emotional and behavioral problems to relational and
            cognitive challenges. This flexible and adaptive method is
            particularly effective in providing a more complete and nuanced
            understanding of the client’s experiences and fostering long-lasting
            positive change. Psychotherapy can address a wide range of mental
            health conditions, such as depression, anxiety, stress, relationship
            problems, trauma, and more.
          </p>
        </>
      ),
    },
    {
      img: img2,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Key aspects of Couple therapy:
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              1. Communication Improvement:
            </span>{" "}
            Therapy helps partners communicate more effectively, express their
            feelings, and listen to each other.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              2. Conflict Resolution:
            </span>{" "}
            Couples learn techniques to resolve conflicts in a healthy manner,
            reducing the negative impact of disagreements.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              3. Understanding Patterns:
            </span>
            Therapists help couples identify and understand patterns in their
            relationship that may be contributing to problems.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              4. Strengthening Bonds:
            </span>
            Therapy can assist in rebuilding trust and intimacy, and in
            strengthening the emotional connection between partners.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              5. Handling Specific Issues:
            </span>
            Specific issues such as infidelity, sexual problems, financial
            stress, and parenting conflicts can be addressed.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              6. Developing Skills:
            </span>
            Couples develop skills to support each other and cope with future
            challenges.
          </p>
        </>
      ),
    },
    {
      img: img3,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Types of Couple Therapy
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Emotionally Focused Therapy (EFT):
            </span>{" "}
            This therapy aims to strengthen the attachment and bonding between
            partners. The therapist assists in recognizing and altering patterns
            that cause
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Gottman Method:
            </span>{" "}
            This approach addresses areas of conflict and equips couples with
            problem- solving skills. Its goal is to enhance the quality of
            friendship and intimacy between partners.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Psychodynamic Couples Therapy:
            </span>
            This therapy delves into the underlying hopes and fears that drive
            behaviors in the relationship, aiding partners in better
            understanding each other.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Behavioral Therapy:
            </span>
            Also known as Behavioral Couples Therapy (BCT), this method focuses
            on reinforcing positive behaviors that promote stability and
            satisfaction, while discouraging negative behaviors.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Cognitive Behavioral Therapy (CBT):
            </span>
            Referred to as Cognitive Behavioral Couples Therapy (CBCT) when
            applied to relationships, this therapy involves identifying and
            changing thought patterns that negatively impact behavior.
          </p>
          <h1 className="text-2xl font-semibold mb-4 mt-10 text-black leading-relaxed">
            How it Works?
          </h1>
          <p className="text-base text-black leading-relaxed">
            Typically, couple therapy is conducted by licensed therapists with
            specialized training in relationships and can be helpful at any
            stage of a relationship, whether partners are dating, engaged,
            married, or in a long-term commitment. The therapy sessions may
            involve both partners together and sometimes individually, depending
            on the approach of the therapist and the needs of the couple.
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="Couple Therapy" breadcrumbTitle="Services" />
        {cardData.map((card, index) => (
          <Text
            key={index}
            img={card.img}
            heading={card.heading}
            text={card.text}
            isReversed={index % 2 !== 0} // Reverse layout for the second card (index 1)
          >
            {card.text} {/* Passing the text content as children */}
          </Text>
        ))}
        <Pagination
          prevLink="/psychotherapy"
          prevText="Psychotherapy"
          nextLink="/interpersonal"
          nextText="Interpersonal Therapy"
        />
        <BrowseButton />
        <ArticleCards cards={cards} />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default CoupleTherapy;
