import React from "react";
import {
  CalendarIcon,
  ClockIcon,
  MapPinIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";

function EventDetails({
  eventImage,
  title,
  startDate,
  endDate,
  doorTime,
  status,
  address,
}) {
  return (
    <>
      <div className="flex flex-col lg:flex-row items-start lg:items-stretch max-w-6xl mx-auto p-6 mt-8 px-4  bg-white gap-6">
        {/* Event Image */}
        <div className="lg:w-2/3 w-full">
          <img
            src={eventImage}
            alt={title || "Event"}
            className="w-full h-full object-cover rounded-lg shadow-lg"
          />
        </div>

        {/* Event Details */}
        <div className="lg:w-1/3 w-full lg:pl-8 flex flex-col p-6">
          <h2 className="text-3xl font-semibold border-b pb-2 mb-4">
            {title || "Event"}
          </h2>

          {/* Start Date */}
          {startDate && (
            <div className="mb-4">
              <div className="flex items-center space-x-2">
                <CalendarIcon className="h-6 w-6 text-gray-800" />
                <span className="text-gray-800 font-semibold">Start Date</span>
              </div>
              <p className="text-gray-700">{startDate}</p>
            </div>
          )}

          {/* End Date */}
          {endDate && (
            <div className="mb-4">
              <div className="flex items-center space-x-2">
                <CalendarIcon className="h-6 w-6 text-gray-800" />
                <span className="text-gray-800 font-semibold">End Date</span>
              </div>
              <p className="text-gray-700">{endDate}</p>
            </div>
          )}

          {/* Door Time */}
          {doorTime && (
            <div className="mb-4">
              <div className="flex items-center space-x-2">
                <ClockIcon className="h-6 w-6 text-gray-800" />
                <span className="text-gray-800 font-semibold">Door Time</span>
              </div>
              <p className="text-gray-700">{doorTime}</p>
            </div>
          )}

          {/* Status */}
          {status && (
            <div className="mb-4">
              <div className="flex items-center space-x-2">
                <CheckCircleIcon className="h-6 w-6 text-gray-800" />
                <span className="text-gray-800 font-semibold">Status</span>
              </div>
              <p className="text-gray-700">{status}</p>
            </div>
          )}

          {/* Address */}
          {address && (
            <div className="mb-4">
              <div className="flex items-center space-x-2">
                <MapPinIcon className="h-6 w-6 text-gray-800" />
                <span className="text-gray-800 font-semibold">Address</span>
              </div>
              <p className="text-gray-700">{address}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default EventDetails;
