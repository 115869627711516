import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Header from "../Comp/Header";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/istockphoto-1090945010-612x612.jpg";

function Special() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="Special-Needs Disorders"
          breadcrumbTitle="Expertise"
        />
        {/* Image */}
        <div className="flex justify-center items-center py-10 mt-4 px-4 sm:px-8 md:px-16 lg:px-32 h-full w-full">
          <img
            src={img1}
            alt="Centered Example"
            className="object-fill rounded-xl shadow-xl shadow-[#f7ece1]"
          />
        </div>

        {/* Text */}
        <div className="py-10  px-4 sm:px-8 md:px-16 lg:px-32">
          <h3 className="text-lg font-semibold mb-2">Disorders</h3>
          <ul className="list-disc list-inside text-black">
            <li>Autism Spectrum Disorder (ASD)</li>
            <li>Attention-Deficit/Hyperactivity Disorder (ADHD)</li>
            <li>Learning Disabilities</li>
            <li>
              Intellectual Disability (Intellectual Developmental Disorder)
            </li>
            <li>Language Disorder</li>
            <li>Speech Sound Disorder</li>
            <li>Childhood-Onset Fluency Disorder (Stuttering)</li>
            <li>Developmental Coordination Disorder</li>
            <li>Hearing Impairment</li>
            <li>Vision Impairment</li>
          </ul>
        </div>
        <Pagination
          prevLink="/childhood-disorder"
          prevText="Childhood Disorder"
          nextLink="/psychological-disorder"
          nextText="Psychological Disorder"
        />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default Special;
