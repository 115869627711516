import React from "react";

function Form() {
  return (
    <>
      <div className="flex flex-col xl:w-3/4 py-14 px-4 md:px-8 lg:px-16 xl:px-32 mb-10 mx-auto">
        <div className="flex bg-slate-100 rounded-xl">
          <form className="mt-10 space-y-4 px-4 md:px-10 mb-14 w-full">
            <h1 className="text-3xl text-dark font-semibold py-6 text-left">
              Submit a Comment
            </h1>
            <p className="text-lg text-dark font-normal py-6 text-left">
              Your email address will not be published. Required fields are
              marked *
            </p>

            <textarea
              rows={4}
              className="w-full p-5 rounded-xl bg-white text-dark"
              placeholder="Comment*"
            />
            <input
              type="text"
              className="w-full md:w-2/3 rounded-lg p-3 bg-white text-dark"
              placeholder="Name*"
            />
            <input
              type="email"
              className="w-full md:w-2/3 rounded-lg p-3 bg-white text-dark"
              placeholder="Email*"
            />
            <input
              type="url"
              className="w-full md:w-2/3 rounded-lg p-3 bg-white text-dark"
              placeholder="Website"
            />

            <div className="flex py-2 space-x-2">
              <input
                type="checkbox"
                className="h-4 w-4 text-gray-600 border-gray-300 rounded focus:ring-gray-500"
              />
              <label className="text-left text-sm text-gray-500">
                Save my name, email, and website in this browser for the next
                time I comment.
              </label>
            </div>

            <div className="flex flex-row-reverse">
              <button
                className="bg-[#61987a] text-white active:bg-lime-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-2xl outline-none focus:outline-none mt-10 ease-linear transition-all duration-150"
                type="button"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Form;
