import React from "react";
import { FaPhoneAlt, FaMapMarkerAlt, FaRegClock } from "react-icons/fa";

function InfoSection() {
  return (
    <>
      <div className="flex flex-col md:flex-row mt-10">
        <div className="flex flex-col w-full md:w-1/3 bg-[#70b96d] hover:bg-[#5b9b59] p-6  min-h-[200px]">
          <div className="flex items-center space-x-4 h-full justify-between">
            <div className="text-sm flex-1">
              <span className="text-white text-3xl">
                <FaRegClock />
              </span>
              <p className="text-base text-white font-medium leading-relaxed mt-4">
                Mon-Fri: 5PM - 8PM
                <br />
                Online Session: 45 minutes
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full md:w-1/3 bg-[#61987a] hover:bg-[#49755c] p-6 min-h-[200px]">
          <div className="flex items-center space-x-4 h-full justify-between">
            <div className="text-sm flex-1">
              <span className="text-white text-3xl">
                <FaMapMarkerAlt />
              </span>
              <p className="text-base text-white leading-relaxed font-medium mt-4">
                Model Town, Haseeb Street, 42-43 B,
                <br />
                Multan, Pakistan.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full md:w-1/3 bg-[#99baa7] hover:bg-[#7b9184] p-6 min-h-[200px]">
          <div className="flex items-center space-x-4 h-full justify-between">
            <div className="text-sm flex-1">
              <span className="text-white text-3xl">
                <FaPhoneAlt />
              </span>
              <p className="text-base text-white leading-relaxed font-medium mt-4">
               061-6510016
                <br />
                <a
                  href="mailto:info@mindfulch.com"
                  className=" hover:underline"
                >
                  info@mindfulch.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InfoSection;
