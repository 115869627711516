import React from "react";

function Pagination({ prevLink, prevText, nextLink, nextText }) {
  return (
    <>
      <div className="flex flex-col md:flex-row h-auto px-6 mb-8 md:px-32 py-8 md:py-14 gap-y-4 md:gap-x-20">
        <div className="flex flex-col w-full md:w-1/2">
          <a
            href={prevLink}
            className="font-semibold text-xs lg:text-base text-[#61987a] hover:text-[#385847] transition ease-in-out duration-300 transform hover:scale-105"
          >
            ← Prev: {prevText}
          </a>
        </div>

        <div className="flex flex-col md:flex-row-reverse w-full md:w-1/2">
          <a
            href={nextLink}
            className="font-semibold text-xs lg:text-base text-[#61987a] hover:text-[#385847] transition ease-in-out duration-300 transform hover:scale-105"
          >
            Next: {nextText} →
          </a>
        </div>
      </div>
    </>
  );
}

export default Pagination;
