import React from "react";
import TopNav from "./TopNav";
import Header from "./Comp/Header";
import Footer from "./Footer";
import img0 from "../assets/composition-natural-material-stationery.jpg";
import img1 from "../assets/anxiety-inducing-imagery-with-angst-feelings.jpg";
import img2 from "../assets/mobile_677411-2575.jpg";
import InfoSection from "./Comp/InfoSection";

function Resources() {
  return (
    <>
      <TopNav transparent />
      <main>
        <Header leftTitle="Mindful Reads" breadcrumbTitle="Blogs" />

        <section>
          <div className="w-full text-center px-4 sm:px-8 md:px-16 mt-16 mb-2">
            <h2 className="text-lg md:text-2xl lg:text-3xl py-1 text-[#4db953] font-sans px-4 ">
              Insights & Articles
            </h2>
            <p className="text-sm md:text-lg font-light text-gray-600 w-full px-4">
              Explore Our Latest Thoughts on Mental Health and Well-Being
            </p>
          </div>
        </section>

        <section>
          <div className="container mx-auto px-4 py-20 mb-12">
            <div className="flex flex-wrap gap-x-4 gap-y-10 items-stretch">
              <div className="w-full md:w-1/3 px-4 mr-auto ml-auto">
                <a href="/digital-detox">
                  <div className="relative flex flex-col min-w-0 break-words w-full h-full shadow-lg hover:shadow-[#cce1d4] transition-all ease-in-out duration-300">
                    <div className="overflow-hidden rounded-t-lg">
                      <img
                        alt="Digital Detox"
                        src={img1}
                        className="w-full h-80 object-cover transition-transform duration-300 transform hover:scale-105"
                      />
                    </div>
                    <blockquote className="relative p-8 mb-4 flex-grow">
                      <h4 className="text-lg font-bold text-stone-700">
                        Digital Detox: Key to IT Professionals’ Health &
                        Productivity
                      </h4>
                      <p className="text-md font-normal mt-2 text-stone-500">
                        by mindfulch.com | Aug 20, 2024
                      </p>
                      <p className="text-md font-normal mt-2 text-stone-700">
                        Introduction In today's sociotechnical world, IT
                        professionals often spend more than 10 hours a day in...
                      </p>
                    </blockquote>
                  </div>
                </a>
              </div>

              <div className="w-full md:w-1/3 px-4 mr-auto ml-auto">
                <a href="/virtual-autism">
                  <div className="relative flex flex-col min-w-0 break-words w-full h-full shadow-lg hover:shadow-[#cce1d4]">
                    <div className="overflow-hidden rounded-t-lg">
                      <img
                        alt="Virtual Autism"
                        src={img2}
                        className="w-full h-80 object-cover transition-transform duration-300 transform hover:scale-105"
                      />
                    </div>
                    <blockquote className="relative p-8 mb-4 flex-grow">
                      <h4 className="text-lg font-bold text-stone-700">
                        Virtual Autism: Understanding the Impact of Screen Time
                        on Child Development
                      </h4>
                      <p className="text-md font-normal mt-2 text-stone-500">
                        by mindfulch.com | Nov 04, 2024
                      </p>
                      <p className="text-md font-normal mt-2 text-stone-700">
                        Introduction With technology becoming an integral part
                        of our lives, children are often exposed to...
                      </p>
                    </blockquote>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <InfoSection />
      </main>
      <Footer />
    </>
  );
}

export default Resources;
