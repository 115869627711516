import React from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import Header from "./Comp/Header";
import { useEffect } from "react";
import InfoSection from "./Comp/InfoSection";
function BookNow() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <TopNav />
      <main>
        <Header
          leftTitle="Schedule Your Meeting!"
          breadcrumbTitle="Make Appointment"
        />
        <section>
          <div
            className="calendly-inline-widget w-full my-12"
            data-url="https://calendly.com/habibanazimkhan/30min"
            style={{ minWidth: "320px", height: "700px" }}
          ></div>
        </section>
        <InfoSection />
      </main>
      <Footer />
    </>
  );
}

export default BookNow;
