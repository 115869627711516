import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/metaphor-memory_23-2151240836.jpg";

function Schizophrenia() {
  return (
    <>
      <div>
  <header>
    <TopNav transparent aria-label="Top Navigation" />
    <Header
      leftTitle="Schizophrenia Awareness"
      breadcrumbTitle="Events"
      aria-labelledby="header-title"
    />
  </header>

  <main>
    <section aria-labelledby="event-details-section">
      <EventDetails
        eventImage={img1}
        title="Events"
        startDate="January 17, 2023 12:00 pm"
        endDate="June 25, 2025 10:45 pm"
        doorTime="10:00 am"
        status="Showing"
        address="123 Event Street, City, Country"
        aria-labelledby="event-details"
      />
    </section>

    <section aria-labelledby="events-text-section">
      <EventsText id="events-text-section" />
    </section>

    <section aria-labelledby="pagination-section">  
      <Pagination
        prevLink="/eating-disorders-awareness"
        prevText="Eating Disorder Awareness Week"
        nextLink="/bipolar-awareness"
        nextText="World Biopolar Day"
        aria-label="Event pagination"
      />
    </section>
  </main>

  <footer>
    <Footer aria-label="Footer" />
  </footer>
</div>

    </>
  );
}

export default Schizophrenia;
