import React from "react";
import Header from "../Comp/Header";
import defaultImage from "../../assets/womans-head.jpg";
import ArticleCards from "../Comp/ArticleCards";
import imgx from "../../assets/pexels-pixabay-163431.jpg";
import imgy from "../../assets/GettyImages-1335839577-43aabea707074ae59e49d709f83c77b4.jpg";
import imgz from "../../assets/360_F_477102217_aP7E3WdgPKK3sxv9beylmt7rDWEgIPvw.jpg";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Pagination from "../Comp/Pagination";
import BrowseButton from "../Comp/BrowseButton";
import Text from "../Comp/Text";
import img1 from "../../assets/rt.jpg";
import img2 from "../../assets/speech-therapy2-600x400.jpg";
import img3 from "../../assets/Expressive-language-disorder-scaled.jpeg";

function SpeechIssues() {
  const cards = [
    {
      imgSrc: imgx,
      title: "Anger Management",
      description:
        "Anger Management refers to techniques and strategies designed to help individuals control...",
      link: "/angermanage",
    },
    {
      imgSrc: imgy,
      title: "Interpersonal Therapy",
      description:
        "Interpersonal Therapy (IPT) is a structured and time-limited psychotherapy that focuses on...",
      link: "/interpersonal",
    },
    {
      imgSrc: imgz,
      title: "Speech Therapy",
      description:
        "Speech therapy is a specialized field focused on diagnosing and treating communication and swallowing...",
      link: "/speechtherapy",
    },
  ];
  const cardData = [
    {
      img: img1,
      text: (
        <>
          <h1 className="text-3xl font-semibold mb-4 text-black leading-relaxed">
            Speech Issues
          </h1>
          <p className="text-base text-black leading-relaxed">
            Speech issues, also known as speech disorders, encompass a variety
            of problems related to the production and articulation of speech.
            These issues can affect the clarity, voice quality, fluency, and
            overall ability to communicate effectively. Speech disorders can
            occur in both children and adults and can range from mild to severe.
          </p>
        </>
      ),
    },
    {
      img: img2,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Types of Speech Issues
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Articulation Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Phonological Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Fluency Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Voice
            Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Resonance Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Aphasia
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Dysarthria
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Selective Mutism
          </p>
        </>
      ),
    },
    {
      img: img3,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Causes of Speech Issues
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Developmental Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Neurological Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Genetic Factors
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Hearing Loss
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Psychological Factors
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Structural Abnormalities
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="Speech Issues" breadcrumbTitle="Services" />
        {cardData.map((card, index) => (
          <Text
            key={index}
            img={card.img}
            heading={card.heading}
            text={card.text}
            isReversed={index % 2 !== 0} // Reverse layout for the second card (index 1)
          >
            {card.text} {/* Passing the text content as children */}
          </Text>
        ))}
        <Pagination
          prevLink="/disorder"
          prevText="Psychological Disorder Treatments"
          nextLink="/specialneeds"
          nextText="Special Needs Issues"
        />
        <BrowseButton />
        <ArticleCards cards={cards} />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default SpeechIssues;
