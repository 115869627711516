import React from "react";
import Header from "../Comp/Header";
import defaultImage from "../../assets/womans-head.jpg";
import ArticleCards from "../Comp/ArticleCards";
import imgx from "../../assets/Blog+Post+Banner_Q&A_about+Couples-Counselling.jpg";
import imgy from "../../assets/DBT-Graphic.png";
import imgz from "../../assets/rt.jpg";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Pagination from "../Comp/Pagination";
import BrowseButton from "../Comp/BrowseButton";
import Text from "../Comp/Text";
import img1 from "../../assets/pexels-photo-4101143.jpeg";
import img2 from "../../assets/psychological-trauma-psychologist-working-depressed-600nw-2087173291.webp";
import img3 from "../../assets/desktop-wallpaper-therapist-high-quality-counseling.jpg";

function Counseling() {
  const cards = [
    {
      imgSrc: imgx,
      title: "Couple Therapy",
      description:
        "Couple therapy, also known as couples counseling or marriage therapy, is a type of psychotherapy ...",
      link: "/coupletherapy",
    },
    {
      imgSrc: imgy,
      title: "Dialectical Behavior Therapy (DBT)",
      description:
        "Dialectical Behavior Therapy (DBT) is a type of cognitive-behavioral therapy developed by...",
      link: "/dbt",
    },
    {
      imgSrc: imgz,
      title: "Speech Issues",
      description:
        "Speech issues, also known as speech disorders, encompass a variety of problems related to the...",
      link: "/speechissues",
    },
  ];
  const cardData = [
    {
      img: img1,
      text: (
        <>
          <h1 className="text-3xl font-semibold mb-4 text-black leading-relaxed">
            Counseling
          </h1>
          <p className="text-base text-black leading-relaxed">
            Counseling is a professional, therapeutic relationship aimed at
            helping individuals navigate personal challenges, emotional
            difficulties, and mental health issues. Through a collaborative
            process, a trained counselor provides a safe, confidential space
            where clients can explore their thoughts, feelings, and behaviors.
            The goal of counseling is to facilitate self-awareness,promote
            personal growth, and develop coping strategies to manage life’s
            stressors. It can address a wide range of issues, including anxiety,
            depression, relationship problems, grief, and trauma. By employing
            various therapeutic techniques and approaches tailored to the
            clients needs, counseling supports individuals in achieving greater
            mental and emotional well-being, enhancing their ability to lead
            fulfilling lives.
          </p>
        </>
      ),
    },
    {
      img: img2,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Key Elements of Counseling
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">1.</span>{" "}
            Confidentiality
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">2.</span>{" "}
            Empathy
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">3.</span>{" "}
            Active Listening
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">4.</span>{" "}
            Judgmental Attitude
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">5.</span> Goal
            Setting
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">6.</span>{" "}
            Support &amp; Guidance
          </p>
        </>
      ),
    },
    {
      img: img3,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Benefits of Counseling
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Enhanced Self-Awareness:
            </span>{" "}
            Helps clients gain insight into their thoughts, feelings, and
            behaviors, promoting personal growth.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Improved Mental Health:
            </span>{" "}
            Reduces symptoms of anxiety, depression, and other mental health
            issues through therapeutic interventions.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Better Coping Strategies
            </span>{" "}
            Equips clients with tools and techniques to manage stress,
            adversity, and life transitions more effectively.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Strengthened Relationships:
            </span>{" "}
            Enhances communication and conflict-resolution skills, improving
            relationships with partners, family, and friends.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Increased Resilience:
            </span>{" "}
            Builds clients ability to bounce back from setbacks and challenges,
            fostering greater resilience.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Greater Emotional Regulation:
            </span>{" "}
            Helps clients understand and manage their emotions, leading to
            improved emotional well-being.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Achieving Personal Goals:
            </span>{" "}
            Supports clients in setting and achieving personal, educational, or
            career-related goals, leading to a more fulfilling life.
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="Counseling" breadcrumbTitle="Services" />
        {cardData.map((card, index) => (
          <Text
            key={index}
            img={card.img}
            heading={card.heading}
            text={card.text}
            isReversed={index % 2 !== 0} // Reverse layout for the second card (index 1)
          >
            {card.text} {/* Passing the text content as children */}
          </Text>
        ))}
        <Pagination
          prevLink="/interpersonal"
          prevText="Interpersonal Therapy"
          nextLink="/career"
          nextText="Career Counseling"
        />
        <BrowseButton />
        <ArticleCards cards={cards} />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default Counseling;
