import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/images (1).jpg";

function WorldSuicide() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="World Suicide Prevention Day"
          breadcrumbTitle="Events"
        />
        <EventDetails
          eventImage={img1}
          title="Events"
          startDate="January 17, 2023 12:00 pm"
          endDate="June 25, 2025 10:45 pm"
          doorTime="10:00 am"
          status="Showing"
          address="123 Event Street, City, Country"
        />
        <EventsText></EventsText>
        <Pagination
          prevLink="/mental-health-awareness"
          prevText="Mental Health Awareness Week"
          nextLink="/national-suicide-awareness"
          nextText="National Suicide Prevention Week"
        />
        <Footer />
      </div>
    </>
  );
}

export default WorldSuicide;
