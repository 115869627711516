import React from "react";
import Header from "../Comp/Header";
import ArticleCards from "../Comp/ArticleCards";
import imgx from "../../assets/GettyImages-1335839577-43aabea707074ae59e49d709f83c77b4.jpg";
import imgy from "../../assets/pexels-photo-4101143.jpeg";
import imgz from "../../assets/360_F_477102217_aP7E3WdgPKK3sxv9beylmt7rDWEgIPvw.jpg";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Pagination from "../Comp/Pagination";
import BrowseButton from "../Comp/BrowseButton";
import Text from "../Comp/Text";
import img1 from "../../assets/istockphoto-1498654263-170667a.webp";
import img2 from "../../assets/167-1679675_psychedelic-therapy-negative.png";
import img3 from "../../assets/DBT-Graphic.png";
import img4 from "../../assets/image-asset.jpeg";
import img5 from "../../assets/Articulation-Graphic-300x224.png";
import img6 from "../../assets/boost-your-immune.jpg";

function Psychotherapy() {
  const cards = [
    {
      imgSrc: imgx,
      title: "Interpersonal Therapy",
      description:
        "Interpersonal Therapy (IPT) is a structured and time-limited psychotherapy that focuses...",
      link: "/interpersonal",
    },
    {
      imgSrc: imgy,
      title: "Counseling",
      description:
        "Counseling is a professional, therapeutic relationship aimed at helping individuals navigate...",
      link: "/counseling",
    },
    {
      imgSrc: imgz,
      title: "Speech Therapy",
      description:
        "Speech therapy is a specialized field focused on diagnosing and treating communication and...",
      link: "/speechtherapy",
    },
  ];
  const cardData = [
    {
      img: img1,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Psychotherapy
          </h1>
          <p className="text-base text-black leading-relaxed">
            Psychotherapy (also called Talk Therapy) is a therapeutic approach
            aimed at helping individuals address and manage emotional,
            psychological, and behavioral challenges. Through various techniques
            and strategies, it facilitates self-awareness, emotional growth, and
            coping skills. Integrated psychotherapy involves blending different
            therapeutic models and techniques to suit the individual client’s
            needs.{" "}
          </p>
          <p className="text-base text-black leading-relaxed">
            By not limiting therapy to a single approach, therapists can draw on
            the strengths of various modalities to address a wide range of
            issues, from emotional and behavioral problems to relational and
            cognitive challenges. This flexible and adaptive method is
            particularly effective in providing a more complete and nuanced
            understanding of the client’s experiences and fostering long-lasting
            positive change. Psychotherapy can address a wide range of mental
            health conditions, such as depression, anxiety, stress, relationship
            problems, trauma, and more.
          </p>
        </>
      ),
    },
    {
      img: img2,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Goals of Psychotherapy
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Reduce Symptoms:{" "}
            </span>{" "}
            Alleviate symptoms of mental health disorders such as anxiety,
            depression, PTSD, and others.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Improve Emotional Regulation:{" "}
            </span>{" "}
            Help clients understand and manage their emotions more effectively.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Enhance Problem-Solving Skills:{" "}
            </span>
            Develop strategies to cope with and solve problems in a healthy way.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Build Healthy Relationships:{" "}
            </span>{" "}
            Improve interpersonal skills and relationships with others. Promote
            Self-Awareness: Increase self-awareness and understanding of one’s
            own thoughts, feelings, and behaviors.
          </p>
        </>
      ),
    },
    {
      img: img3,
      text: (
        <>
          <h1 className="text-3xl font-semibold mb-8 text-black leading-relaxed">
            Techniques
          </h1>
          <h1 className="text-2xl font-base mb-4 text-black leading-relaxed">
            1. Dialectical Behavior Therapy (DBT)
          </h1>
          <p className="text-base text-black leading-relaxed">
            Dialectical Behavior Therapy (DBT) is a comprehensive,
            evidence-based treatment designed to help individuals manage intense
            emotions and improve relationships. It emphasizes four core skills:
            emotional regulation, which helps individuals understand and control
            their emotions; distress tolerance, which provides strategies to
            cope with painful situations; mindfulness, which promotes
            present-moment awareness; and interpersonal effectiveness, which
            enhances communication and relationship skills. DBT is particularly
            effective for those experiencing severe emotional distress and is
            often used to treat conditions like borderline personality disorder.
          </p>
        </>
      ),
    },
    {
      img: img4,
      text: (
        <>
          <h1 className="text-2xl font-base mb-4 text-black leading-relaxed">
            2. Cognitive Behavioral Therapy (CBT)
          </h1>
          <p className="text-base text-black leading-relaxed">
            Cognitive Behavioral Therapy (CBT) is a structured, evidence-based
            treatment that focuses on breaking the vicious cycle of negative
            thinking. It emphasizes how thoughts create feelings, feelings
            create behaviors, and behaviors reinforce thoughts. By identifying
            and challenging negative thought patterns, individuals can alter
            their emotional responses and behavioral reactions, leading to more
            positive outcomes. This approach helps individuals develop healthier
            ways of thinking and behaving, making it effective for treating
            various mental health issues like anxiety and depression.
          </p>
        </>
      ),
    },
    {
      img: img5,
      text: (
        <>
          <h1 className="text-2xl font-base mb-4 text-black leading-relaxed">
            3. Speech Articulation Therapy
          </h1>
          <p className="text-base text-black leading-relaxed">
            Speech articulation therapy is a specialized approach aimed at
            improving a person’s ability to produce clear and accurate speech
            sounds. It involves structured practice, starting with the correct
            production of individual sounds, then progressing to words, phrases,
            and sentences. Ultimately, the goal is to achieve fluent and
            intelligible conversation. Through repetitive exercises and targeted
            techniques, individuals can enhance their speech clarity and
            communication effectiveness, which is particularly beneficial for
            those with speech sound disorders.
          </p>
        </>
      ),
    },
    {
      img: img6,
      text: (
        <>
          <h1 className="text-2xl font-base mb-4 text-black leading-relaxed">
            4. Mindfulness-Based Therapies
          </h1>
          <p className="text-base text-black leading-relaxed">
            Mindfulness-based therapies incorporate meditation and techniques to
            cultivate present-moment awareness, promoting emotional balance and
            stress reduction. By fostering a non-judgmental attitude towards
            thoughts and sensations, these therapies help individuals manage
            stress and improve overall well-being. They teach practical
            techniques like deep breathing, body scanning, and mindful movement
            to enhance self-awareness and resilience. Mindfulness-based
            approaches are widely used in treating anxiety, depression, and
            other mental health conditions, emphasizing the connection between
            mind and body for holistic healing.
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="Psychotherapy" breadcrumbTitle="Services" />
        {cardData.map((card, index) => (
          <Text
            key={index}
            img={card.img}
            heading={card.heading}
            text={card.text}
            isReversed={index % 2 !== 0} // Reverse layout for the second card (index 1)
          >
            {card.text} {/* Passing the text content as children */}
          </Text>
        ))}
        <Pagination
          prevLink="/relaxationtechniques"
          prevText="Relaxation Techniques"
          nextLink="/coupletherapy"
          nextText="Couple Therapy"
        />
        <BrowseButton />
        <ArticleCards cards={cards} />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default Psychotherapy;
