import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/167-1679675_psychedelic-therapy-negative.png";

function NationalMental() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="National Mental Health Awareness Month"
          breadcrumbTitle="Events"
        />
        <EventDetails
          eventImage={img1}
          title="Events"
          startDate="January 17, 2023 12:00 pm"
          endDate="June 25, 2025 10:45 pm"
          doorTime="10:00 am"
          status="Showing"
          address="123 Event Street, City, Country"
        />
        <EventsText></EventsText>
        <Pagination
          prevLink="/national-suicide-prevention"
          prevText="National Suicide Prevention Week"
          nextLink="/mental-health-day"
          nextText="World Mental Health Day"
        />
        <Footer />
      </div>
    </>
  );
}

export default NationalMental;
