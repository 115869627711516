import React from "react";
import Header from "../Comp/Header";
import defaultImage from "../../assets/womans-head.jpg";
import ArticleCards from "../Comp/ArticleCards";
import imgx from "../../assets/depositphotos_236221024-stock-photo-depressed-man-hiding-his-eyes.jpg";
import imgy from "../../assets/360_F_588397002_iHlsXnpbCdBkkwQ9okBaReQkybMZseRc.jpg";
import imgz from "../../assets/istockphoto-1498654263-170667a.webp";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Pagination from "../Comp/Pagination";
import BrowseButton from "../Comp/BrowseButton";
import Text from "../Comp/Text";
import img1 from "../../assets/GettyImages-1335839577-43aabea707074ae59e49d709f83c77b4.jpg";
import img2 from "../../assets/interpersonal.jpg";
import img3 from "../../assets/How-effective-is-Interpersonal-Therapy-IPT-1-scaled.webp";

function Interpersonal() {
  const cards = [
    {
      imgSrc: imgx,
      title: "Psychological Disorder Treatment",
      description:
        "Psychological disorders, also known as mental disorders or mental illnesses, are patterns...",
      link: "/disorder",
    },
    {
      imgSrc: imgy,
      title: "Special Needs Issues",
      description:
        "Special needs issues refer to a variety of physical, developmental, behavioral, or emotional...",
      link: "/specialneeds",
    },
    {
      imgSrc: imgz,
      title: "Psychotherapy",
      description:
        "Psychotherapy (also called Talk Therapy) is a therapeutic approach aimed at helping individuals...",
      link: "/psychotherapy",
    },
  ];
  const cardData = [
    {
      img: img1,
      text: (
        <>
          <h1 className="text-3xl font-semibold mb-4 text-black leading-relaxed">
            Interpersonal Therapy
          </h1>
          <p className="text-base text-black leading-relaxed">
            Interpersonal Therapy (IPT) is a structured and time-limited
            psychotherapy that focuses on improving interpersonal relationships
            and addressing interpersonal issues that contribute to psychological
            distress. IPT is based on the premise that unresolved interpersonal
            conflicts and difficulties in relationships can significantly impact
            an individuals emotional well-being. The therapy aims to help
            clients identify and address specific problems in their
            relationships, such as role transitions, grief, interpersonal
            disputes, and interpersonal deficits (i.e., difficulties in relating
            to others). By improving communication skills, exploring emotions
            related to relationships, and making practical changes in how one
            interacts with others, IPT aims to alleviate symptoms of depression,
            anxiety, and other mental health disorders.
          </p>
        </>
      ),
    },
    {
      img: img2,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Types of Interpersonal Therapy
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Grief-focused IPT:
            </span>{" "}
            This type of IPT helps individuals cope with unresolved grief and
            loss. It aims to facilitate the mourning process and address any
            difficulties in adapting to life without the deceased person.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Role Transition IPT:
            </span>{" "}
            Focuses on life changes such as starting a new job, becoming a
            parent, or retiring, which can create stress and impact
            relationships. This IPT helps individuals navigate these transitions
            and adjust to new roles.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Interpersonal Disputes IPT:
            </span>
            Deals with conflicts or disagreements within relationships. It helps
            individuals communicate more effectively, resolve conflicts, and
            restore harmony in their relationships.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Interpersonal Deficits IPT:
            </span>
            Targets difficulties in building and maintaining relationships. It
            focuses on improving social skills, enhancing assertiveness, and
            addressing patterns of isolation or avoidance.
          </p>
        </>
      ),
    },
    {
      img: img3,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Benefits of Interpersonal Therapy
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Improving Communication:
            </span>{" "}
            Enhances interpersonal skills and communication patterns, leading to
            better relationships.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Reducing Symptoms:
            </span>{" "}
            Effective in alleviating symptoms of depression, anxiety, and other
            mood disorders by addressing underlying interpersonal issues.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Enhancing Coping Mechanisms:
            </span>
            Provides individuals with new coping strategies for managing
            relationship stress and life transitions.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Time-Limited and Structured:
            </span>
            IPT is typically shorter in duration compared to some other
            therapies, making it more accessible and focused for clients.
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="Interpersonal Therapy" breadcrumbTitle="Services" />
        {cardData.map((card, index) => (
          <Text
            key={index}
            img={card.img}
            heading={card.heading}
            text={card.text}
            isReversed={index % 2 !== 0} // Reverse layout for the second card (index 1)
          >
            {card.text} {/* Passing the text content as children */}
          </Text>
        ))}
        <Pagination
          prevLink="/coupletherapy"
          prevText="Couple Therapy"
          nextLink="/counseling"
          nextText="Counseling"
        />
        <BrowseButton />
        <ArticleCards cards={cards} />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default Interpersonal;
