import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/artwork-vincent-van.jpg";

function Biopolar() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="World Biopolar Day" breadcrumbTitle="Events" />
        <EventDetails
          eventImage={img1}
          title="Events"
          startDate="January 17, 2023 12:00 pm"
          endDate="June 25, 2025 10:45 pm"
          doorTime="10:00 am"
          status="Showing"
          address="123 Event Street, City, Country"
        />
        <EventsText>
          {/*World Bipolar Day*/}

          <h3 className="text-2xl font-semibold mb-2 mt-4">
            World Bipolar Day
          </h3>
          <p className="text-base font-normal">
            Each year, World Bipolar Day (WBD) is celebrated on March 30, aiming
            to raise global awareness about bipolar conditions and eliminate the
            social stigma associated with them. This significant day is
            organized by the International Bipolar Foundation in partnership
            with the Asian Network of Bipolar Disorder and the International
            Society for Bipolar Disorders.
          </p>
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Vincent Van Gogh and World Bipolar Day
          </h3>
          <p className="text-base font-normal">
            World Bipolar Day is celebrated on March 30, the birthday of Vincent
            Van Gogh, who was retrospectively diagnosed as probably having a
            bipolar condition. This connection highlights the historical and
            cultural significance of the day.
          </p>

          {/* Vision and Goals */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Vision and Goals</h3>
          <p className="text-base font-normal">
            The vision of World Bipolar Day is to:
          </p>
          <p className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
              Raise Global Awareness:
            </span>{" "}
            Educate the world about bipolar disorders.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Eliminate Social Stigma:
            </span>{" "}
            Combat the misconceptions and prejudices surrounding the condition.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Provide Educational Information:
            </span>{" "}
            Help people understand bipolar disorder, its symptoms, and its
            impact.
          </p>

          {/* Bipolar Disorder Overview */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Bipolar Disorder Overview
          </h3>
          <p className="text-base font-normal">
            Bipolar disorder is a lifelong mental health condition that
            primarily affects a person’s mood. It can cause significant changes
            in how one feels and acts, with episodes of:
          </p>
          <p className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
              1. Manic Episodes:
            </span>{" "}
            During mania, individuals may experience elevated mood, increased
            energy, excessive talkativeness, racing thoughts, inflated self-
            esteem, decreased need for sleep, distractibility, and impulsive or
            reckless behavior.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              2. Depressive Episodes:
            </span>{" "}
            These are marked by feelings of deep sadness, irritability,
            emptiness, or loss of interest in activities, coupled with changes
            in appetite and sleep, fatigue, feelings of worthlessness, and
            thoughts of death or suicide.
          </p>
          <p className="text-base font-normal mt-4">
            Individuals with bipolar disorder may feel well between these
            episodes, but the symptoms can severely impact various aspects of
            life, including work, school, and relationships.
          </p>

          {/* Impact on Life */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Impact on Life</h3>
          <p className="text-base font-normal">
            The symptoms of bipolar disorder can be severe and affect various
            aspects of a person’s life, including:
          </p>
          <p className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
              Work and Career:
            </span>{" "}
            Difficulty maintaining consistent performance, meeting deadlines,
            interacting with colleagues and to cater a successful career.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Education:
            </span>{" "}
            Challenges in focusing, attending classes regularly, and completing
            assignments.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Relationships:
            </span>{" "}
            Strain on relationships with family, friends, and partners due to
            unpredictable mood changes.
          </p>

          {/* Importance of Awareness and Education */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Importance of Awareness and Education
          </h3>
          <p className="text-base font-normal">
            Raising awareness about bipolar disorder is crucial because:
          </p>
          <p className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
              Prevalence:
            </span>{" "}
            In 2019, approximately 40 million people worldwide experienced
            bipolar disorder.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Mental Health Statistics:
            </span>{" "}
            1 in every 8 people globally lives with a mental disorder, which
            involves significant disturbances in thinking, emotional regulation,
            or behavior.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Access to Care:
            </span>{" "}
            Despite the existence of effective prevention and treatment options,
            most people do not have access to the necessary care.
          </p>

          {/* Effective Treatment Options */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Effective Treatment Options
          </h3>
          <p className="text-base font-normal">
            Several effective treatment options are available for managing
            bipolar disorder, including:
          </p>
          <p className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
              Psychoeducation:
            </span>{" "}
            Educating individuals and their families about the disorder, its
            symptoms, and management strategies.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Stress Reduction:
            </span>{" "}
            Implementing techniques to reduce stress, such as mindfulness,
            meditation, and relaxation exercises.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Strengthening Social Functioning:
            </span>{" "}
            Enhancing social skills and building a robust support network to
            improve interpersonal relationships.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Medication:
            </span>{" "}
            Using prescribed medications, such as mood stabilizers,
            antipsychotics, and antidepressants, to manage symptoms and maintain
            stability.
          </p>

          {/* How to Participate */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            How to Participate
          </h3>
          <p className="text-base font-normal">
            This year, the public is encouraged to use social media to spread
            information about bipolar disorder and get the event’s hashtags
            trending. Here’s how you can participate:
          </p>
          <p className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
              Share Information:
            </span>{" "}
            Post educational content about bipolar disorder on your social media
            platforms.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Use Hashtags:
            </span>{" "}
            Use the official World Bipolar Day hashtags to join the global
            conversation and raise awareness.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Support and Encourage:
            </span>{" "}
            Share personal stories, support those affected by bipolar disorder,
            and encourage others to learn more about the condition.
          </p>
        </EventsText>
        <Pagination
          prevLink="/schizophrenia-awareness"
          prevText="Schizophrenia Awareness"
          nextLink="/ptsd-awareness"
          nextText="National PTSD Awareness Day"
        />
        <Footer />
      </div>
    </>
  );
}

export default Biopolar;
