import React from "react";
import Header from "../Comp/Header";
import defaultImage from "../../assets/womans-head.jpg";
import ArticleCards from "../Comp/ArticleCards";
import imgx from "../../assets/speech-therapy2-600x400.jpg";
import imgy from "../../assets/167-1679675_psychedelic-therapy-negative.png";
import imgz from "../../assets/psychological-trauma-psychologist-working-depressed-600nw-2087173291.webp";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Pagination from "../Comp/Pagination";
import BrowseButton from "../Comp/BrowseButton";
import Text from "../Comp/Text";
import img1 from "../../assets/pexels-olly-3755761.jpg";
import img2 from "../../assets/stressmanagement.jpg";
import img3 from "../../assets/yoga-6723315_640.jpg";

function StressManage() {
  const cards = [
    {
      imgSrc: imgx,
      title: "Speech Issues",
      description:
        "Speech issues, also known as speech disorders, encompass a variety of problems related ...",
      link: "/speechissues",
    },
    {
      imgSrc: imgy,
      title: "Psychotherapy",
      description:
        "Psychotherapy (also called Talk Therapy) is a therapeutic approach aimed at helping individuals...",
      link: "/psychotherapy",
    },
    {
      imgSrc: imgz,
      title: "Counseling",
      description:
        "Counseling is a professional, therapeutic relationship aimed at helping individuals navigate...",
      link: "/counseling",
    },
  ];
  const cardData = [
    {
      img: img1,
      text: (
        <>
          <h1 className="text-3xl font-semibold mb-4 text-black leading-relaxed">
            Stress Management
          </h1>
          <p className="text-base text-black leading-relaxed">
            Stress is a natural physical and emotional response to challenges,
            demands, or threats. It can be triggered by both positive and
            negative experiences and can vary in intensity from mild to severe.
            Stress is the body’s way of preparing to face a threat or challenge,
            often referred to as the “fight-or-flight” response. Stress
            Management refers to a wide range of techniques and psychotherapies
            aimed at controlling a person’s levels of stress, especially chronic
            stress, to improve functioning and overall well-being.
          </p>
        </>
      ),
    },
    {
      img: img2,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Techniques and Strategies for Stress Management
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Identifying Stressors
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Healthy Lifestyle Choices
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Regular Exercise
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Balanced Diet
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Adequate Sleep
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Avoiding Substance Abuse
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Relaxation Techniques
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Time
            Management
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Social
            Support
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Cognitive-Behavioral Techniques
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Healthy Boundaries
          </p>
        </>
      ),
    },
    {
      img: img3,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Benefits of Effective Stress Management
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Improved Physical Health
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Enhanced Mental Health
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Better
            Relationships
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Increased Productivity
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Overall Well-being and happiness
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="Stress Management" breadcrumbTitle="Services" />
        {cardData.map((card, index) => (
          <Text
            key={index}
            img={card.img}
            heading={card.heading}
            text={card.text}
            isReversed={index % 2 !== 0} // Reverse layout for the second card (index 1)
          >
            {card.text} {/* Passing the text content as children */}
          </Text>
        ))}
        <Pagination
          prevLink="/dbt"
          prevText="Dialectical Behavior Therapy (DBT)"
          nextLink="/angermanage"
          nextText="Anger Management"
        />
        <BrowseButton />
        <ArticleCards cards={cards} />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default StressManage;
