import React from "react";
import Header from "../Comp/Header";
import defaultImage from "../../assets/womans-head.jpg";
import ArticleCards from "../Comp/ArticleCards";
import imgx from "../../assets/speech-therapy2-600x400.jpg";
import imgy from "../../assets/167-1679675_psychedelic-therapy-negative.png";
import imgz from "../../assets/istockphoto-978274922-612x612.jpg";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Pagination from "../Comp/Pagination";
import BrowseButton from "../Comp/BrowseButton";
import Text from "../Comp/Text";
import img1 from "../../assets/What-Are-The-Potential-Side-Effects-Of-EMDR-Therapy.jpg.optimal.jpg";
import img2 from "../../assets/Untitled-design-2024-04-08T155232.171-1024x791.webp";
import img3 from "../../assets/emdr-1024x575.jpg";
import img4 from "../../assets/EDGE_EMDR.webp";
import img5 from "../../assets/EMDR-Therapy-for-Teens.jpg";

function Emdr() {
  const cards = [
    {
      imgSrc: imgx,
      title: "Speech Issues",
      description:
        "Speech issues, also known as speech disorders, encompass a variety of problems related ...",
      link: "/speechissues",
    },
    {
      imgSrc: imgy,
      title: "Psychotherapy",
      description:
        "Psychotherapy (also called Talk Therapy) is a therapeutic approach aimed at helping individuals...",
      link: "/psychotherapy",
    },
    {
      imgSrc: imgz,
      title: "Counseling",
      description:
        "Counseling is a professional, therapeutic relationship aimed at helping individuals navigate...",
      link: "/counseling",
    },
  ];
  const cardData = [
    {
      img: img1,
      text: (
        <>
          <h1 className="text-3xl font-semibold mb-4 text-black leading-relaxed">
            EMDR (Eye Movement Desensitization and Reprocessing)
          </h1>
          <p className="text-base text-black leading-relaxed">
            EMDR (Eye Movement Desensitization and Reprocessing) is a
            psychotherapy technique designed to help individuals process and
            heal from traumatic experiences and distressing memories. It
            involves the use of bilateral stimulation, often through guided eye
            movements, to help the brain reprocess trauma and reduce emotional
            distress.
          </p>
        </>
      ),
    },
    {
      img: img2,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Key Components of EMDR
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Bilateral Stimulation
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Target
            Memory Identification
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Desensitization
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Cognitive Reprocessing
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Body
            Scan
          </p>
        </>
      ),
    },
    {
      img: img3,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Uses of EMDR
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Trauma
            and PTSD
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Anxiety and Panic
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Depression
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Grief
            and Loss:
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Phobias and Stress
          </p>
        </>
      ),
    },
    {
      img: img4,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Benefits of EMDR
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Faster
            Recovery
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Reduced Emotional Intensity
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Improved Coping
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Long-Lasting Results
          </p>
        </>
      ),
    },
    {
      img: img5,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            What EMDR is Used For
          </h1>
          <p className="text-base text-black leading-relaxed">
            EMDR is most commonly used for treating:
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Post-Traumatic Stress Disorder (PTSD)
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Childhood Trauma
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Complex Trauma
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Anxiety Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Depression
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Phobias
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Grief
            and Loss
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Addiction and Substance Abuse
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="Eye Movement Desensitization and Reprocessing"
          breadcrumbTitle="Services"
        />
        {cardData.map((card, index) => (
          <Text
            key={index}
            img={card.img}
            heading={card.heading}
            text={card.text}
            isReversed={index % 2 !== 0} // Reverse layout for the second card (index 1)
          >
            {card.text} {/* Passing the text content as children */}
          </Text>
        ))}
        <Pagination
          prevLink="/cbt"
          prevText="Cognitive Behavior Therapy (CBT)"
          nextLink="/psychotherapy"
          nextText="Psychotherapy"
        />
        <BrowseButton />
        <ArticleCards cards={cards} />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default Emdr;
