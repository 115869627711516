import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/hand-drawn-ptsd.jpg";

function PtsdAwareness() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="National PTSD Awareness Day"
          breadcrumbTitle="Events"
        />
        <EventDetails
          eventImage={img1}
          title="Events"
          startDate="January 17, 2023 12:00 pm"
          endDate="June 25, 2025 10:45 pm"
          doorTime="10:00 am"
          status="Showing"
          address="123 Event Street, City, Country"
        />
        <EventsText></EventsText>
        <Pagination
          prevLink="/bipolar-awareness"
          prevText="World Biopolar Day"
          nextLink="/child-awareness"
          nextText="Children's Mental Health Awareness Week"
        />
        <Footer />
      </div>
    </>
  );
}

export default PtsdAwareness;
