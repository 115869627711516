import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import img from "../assets/Logoo.png";
export default function Footer() {
  return (
    <footer className="relative bg-[#f0f4f2] pt-8 pb-6">
      <div className="container mx-auto px-2 md:px-4">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="mb-2">
              <img
                src={img}
                alt="Mindful Connection Hub Logo"
                className="w-60 h-auto" // Adjust width and height as needed
              />
            </div>
            <h5 className="text-base md:text-lg mt-1 mb-2 text-stone-600">
              Our vision is to seamlessly integrate IT and psychology, providing
              innovative and effective mental health solutions that enhance
              well-being.
            </h5>
            <div className="mt-6 flex">
              <button
                className="w-8 h-8 flex items-center justify-center  rounded-sm outline-none focus:outline-none mr-2 mb-1  hover:bg-[#a7ccb7] hover:rounded-sm duration-500"
                type="button"
              >
                <a
                  href="https://www.instagram.com/habibanazimkhan/"
                  target="_blank"
                >
                  <FaInstagram className="text-2xl text-gray-600" />
                </a>
              </button>
              <button
                className=" w-8 h-8 flex items-center justify-center rounded-sm outline-none focus:outline-none mr-2 mb-1  hover:bg-[#a7ccb7] hover:rounded-sm duration-500"
                type="button"
              >
                <a
                  href="https://www.linkedin.com//company/mindful-connection-hub"
                  target="_blank"
                >
                  <FaLinkedin className="text-2xl text-gray-600" />
                </a>
              </button>
              <button
                className=" w-8 h-8 flex items-center justify-center rounded-sm outline-none focus:outline-none mr-2 mb-1  hover:bg-[#a7ccb7] hover:rounded-sm duration-500"
                type="button"
              >
                <a
                  href="https://www.facebook.com/profile.php?id=61561362483687"
                  target="_blank"
                >
                  <FaFacebook className="text-2xl text-gray-600" />
                </a>
              </button>
            </div>
          </div>

          <div className="w-full lg:w-6/12 mt-10 sm:mt-0">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-[#56876b] text-lg font-semibold mb-2">
                  Important Links
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-stone-600 hover:text-[#5d9676] font-semibold block pb-2 text-sm"
                      href="/"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-stone-600 hover:text-[#5d9676] font-semibold block pb-2 text-sm"
                      href="/about"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-stone-600 hover:text-[#5d9676] font-semibold block pb-2 text-sm"
                      href="/contactus"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-stone-600 hover:text-[#5d9676] font-semibold block pb-2 text-sm"
                      href="/privacypolicy"
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-stone-500 border-x-4" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <h1 className="text-stone-500">
              © All Rights Reserved. Arwa Technologies 2024
            </h1>
          </div>
        </div>
      </div>
    </footer>
  );
}
