import React from "react";
import Header from "../Comp/Header";
import defaultImage from "../../assets/womans-head.jpg";
import ArticleCards from "../Comp/ArticleCards";
import imgx from "../../assets/speech-therapy2-600x400.jpg";
import imgy from "../../assets/167-1679675_psychedelic-therapy-negative.png";
import imgz from "../../assets/istockphoto-978274922-612x612.jpg";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Pagination from "../Comp/Pagination";
import BrowseButton from "../Comp/BrowseButton";
import Text from "../Comp/Text";
import img1 from "../../assets/360_F_588397002_iHlsXnpbCdBkkwQ9okBaReQkybMZseRc.jpg";
import img2 from "../../assets/lifestyle-child.jpg";
import img3 from "../../assets/istockphoto-472977300-612x612.jpg";
import img4 from "../../assets/disability-kids-flat-icons-set-600nw-2291049301.webp";

function SpecialNeeds() {
  const cards = [
    {
      imgSrc: imgx,
      title: "Speech Issues",
      description:
        "Speech issues, also known as speech disorders, encompass a variety of problems related ...",
      link: "/speechissues",
    },
    {
      imgSrc: imgy,
      title: "Psychotherapy",
      description:
        "Psychotherapy (also called Talk Therapy) is a therapeutic approach aimed at helping individuals...",
      link: "/psychotherapy",
    },
    {
      imgSrc: imgz,
      title: "Counseling",
      description:
        "Counseling is a professional, therapeutic relationship aimed at helping individuals navigate...",
      link: "/counseling",
    },
  ];
  const cardData = [
    {
      img: img1,
      text: (
        <>
          <h1 className="text-3xl font-semibold mb-4 text-black leading-relaxed">
            Special Needs Issues
          </h1>
          <p className="text-base text-black leading-relaxed">
            Special needs issues refer to a variety of physical, developmental,
            behavioral, or emotional conditions that require specific
            interventions and supports. These issues can affect a person’s
            ability to perform everyday activities and can impact their
            learning, social interactions, and independence. Here are some
            common special needs issues.
          </p>
        </>
      ),
    },
    {
      img: img2,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Types of Special Needs Issues
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Developmental Disabilities
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Learning Disabilities
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Behavioral and Emotional Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Sensory Processing Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Physical Disabilities
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Hearing Impairment
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Speech
            Impairment
          </p>
        </>
      ),
    },
    {
      img: img3,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Challenges and Needs
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Educational Needs
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Social
            and Emotional Support
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Medical and Health Care
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Daily
            Living and Independence
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Family
            and Caregiver Support
          </p>
        </>
      ),
    },
    {
      img: img4,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Strategies for Addressing Special Needs Issues
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Early
            Intervention
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Collaborative Approach
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Person-Centered Planning
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Continued Education and Training
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="Special Needs Issues" breadcrumbTitle="Services" />
        {cardData.map((card, index) => (
          <Text
            key={index}
            img={card.img}
            heading={card.heading}
            text={card.text}
            isReversed={index % 2 !== 0} // Reverse layout for the second card (index 1)
          >
            {card.text} {/* Passing the text content as children */}
          </Text>
        ))}
        <Pagination
          prevLink="/speechissues"
          prevText="Speech Issues"
          nextLink="/relaxationtechniques"
          nextText="Relaxation Techniques"
        />
        <BrowseButton />
        <ArticleCards cards={cards} />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default SpecialNeeds;
