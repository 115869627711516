import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Header from "../Comp/Header";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/istockphoto-1217131611-612x612.jpg";

function Childhood() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="Childhood Disorders" breadcrumbTitle="Expertise" />
        {/* Image */}
        <div className="flex justify-center items-center py-10 mt-4 px-4 sm:px-8 md:px-16 lg:px-32 h-full w-full">
          <img
            src={img1}
            alt="Centered Example"
            className="object-fill rounded-xl shadow-xl shadow-[#f7ece1]"
          />
        </div>

        {/* Text */}
        <div className="py-10  px-4 sm:px-8 md:px-16 lg:px-32">
          <h3 className="text-lg font-semibold mb-2">Disorders</h3>
          <ul className="list-disc list-inside text-black">
            <li>Anxiety Disorders</li>
            <li>Major Depressive Disorder</li>
            <li>Specific Learning Disorder</li>
            <li>Oppositional Defiant Disorder (ODD)</li>
            <li>Conduct Disorder</li>
            <li>Obsessive-Compulsive Disorder (OCD)</li>
            <li>Post-Traumatic Stress Disorder (PTSD)</li>
            <li>Feeding and Eating Disorders</li>
            <li>Tic Disorder</li>
            <li>Selective Mutism</li>
            <li>Reactive Attachmen</li>
            <li>Disorder (RAD)</li>
            <li>Disinhibited Social</li>
            <li>Engagement Disorder</li>
            <li>Encopresis (Elimination Disorder)</li>
            <li>Enuresis (Elimination Disorder)</li>
            <li>Disruptive Mood</li>
            <li>Dysregulation Disorder (DMDD)</li>
          </ul>
        </div>
        <Pagination
          prevLink="/psychological-disorder"
          prevText="Psychological Disorder"
          nextLink="/special-needs-disorder"
          nextText="Special Needs Disorder"
        />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default Childhood;
