import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Header from "../Comp/Header";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/panic-attack.webp";

function Psychological() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="Psychological Disorders"
          breadcrumbTitle="Expertise"
        />
        {/* Image */}
        <div className="flex justify-center items-center py-10 mt-4 px-4 sm:px-8 md:px-16 lg:px-32 h-full w-full">
          <img
            src={img1}
            alt="Centered Example"
            className="object-fill rounded-xl shadow-xl shadow-[#f7ece1]"
          />
        </div>

        {/* Text */}
        <div className="py-10  px-4 sm:px-8 md:px-16 lg:px-32">
          <h3 className="text-lg font-semibold mb-2">Disorders</h3>
          <ul className="list-disc list-inside text-black">
            <li>Major Depressive Disorder</li>
            <li>Bipolar Disorders</li>
            <li> Schizophrenia Spectrum and Other Psychotic Disorders</li>
            <li>Obsessive-Compulsive Disorder (OCD)</li>
            <li>Post-Traumatic Stress Disorder (PTSD) Generalized Anxiety</li>
            <li>Disorder (GAD) Panic Disorder</li>
            <li>Agoraphobia Social Anxiety Disorder</li>
            <li>Dissociative Disorders</li>
            <li>Somatic Symptom Disorder</li>
            <li>Illness Anxiety Disorder (Hypochondriasis)</li>
            <li>Conversion Disorder</li>
            <li>Eating Disorders</li>
            <li>Sleep-Wake Disorders</li>
            <li>Substance Use Disorders</li>
            <li>Personality Disorders</li>
            <li>Neurocognitive Disorders</li>
            <li>Gender Dysphoria</li>
            <li>Paraphilic Disorders</li>
            <li>Sexual Dysfunctions</li>
            <li>Disruptive, Impulse</li>
            <li>Control, and Conduct Disorders</li>
            <li>Neurological Disorders</li>
          </ul>
        </div>
        <Pagination
          prevLink="/special-needs-disorder"
          prevText="Special Needs Disorder"
          nextLink="/childhood-disorder"
          nextText="Childhood Disorder"
        />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default Psychological;
