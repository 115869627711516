import React from "react";
import Header from "../Comp/Header";
import defaultImage from "../../assets/womans-head.jpg";
import ArticleCards from "../Comp/ArticleCards";
import imgx from "../../assets/6-techniques-for-stress-web.jpg";
import imgy from "../../assets/rt.jpg";
import imgz from "../../assets/GettyImages-1198265963-1024x683.webp";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Pagination from "../Comp/Pagination";
import BrowseButton from "../Comp/BrowseButton";
import Text from "../Comp/Text";
import img1 from "../../assets/woman-doing-speech-therapy.jpg";
import img2 from "../../assets/6.jpg";
import img3 from "../../assets/360_F_477102217_aP7E3WdgPKK3sxv9beylmt7rDWEgIPvw.jpg";

function SpeechTherapy() {
  const cards = [
    {
      imgSrc: imgx,
      title: "Relaxation Techniques",
      description:
        "Relaxation techniques in the therapeutic process refer to a set of practices and strategies used to...",
      link: "/relaxationtechniques",
    },
    {
      imgSrc: imgy,
      title: "Speech Issues",
      description:
        "Speech issues, also known as speech disorders, encompass a variety of problems related to...",
      link: "/speechissues",
    },
    {
      imgSrc: imgz,
      title: "Psychological Disorder Treatments",
      description:
        "Psychological disorders, also known as mental disorders or mental illnesses, are patterns of...",
      link: "/disorder",
    },
  ];
  const cardData = [
    {
      img: img1,
      text: (
        <>
          <h1 className="text-3xl font-semibold mb-4 text-black leading-relaxed">
            Speech Therapy
          </h1>
          <p className="text-base text-black leading-relaxed">
            Speech therapy is a specialized field focused on diagnosing and
            treating communication and swallowing disorders. Conducted by
            trained speech- language pathologists (SLPs), it addresses a range
            of issues including speech sound production, fluency, voice quality,
            and language skills. Therapy involves individualized assessments to
            identify specific challenges and the development of tailored
            treatment plans to improve articulation, language comprehension,
            expressive language, and cognitive-communication skills.
          </p>
        </>
      ),
    },
    {
      img: img2,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Key Aspects of Speech Therapy
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Assessment and Diagnosis
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Treatment of Speech Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Language Intervention
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Cognitive-Communication Therapy
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Augmentative and Alternative Communication (AAC)
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Individualized Treatment Plans
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Family
            and Caregiver Involvement
          </p>
        </>
      ),
    },
    {
      img: img3,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Benefits of Speech Therapy
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Improved Communication Skills
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Enhanced Language Development
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Increased Confidence
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Fluency Improvement
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Cognitive-Communication Skills
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Quality of Life
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Social
            Interaction
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="Speech Therapy" breadcrumbTitle="Services" />
        {cardData.map((card, index) => (
          <Text
            key={index}
            img={card.img}
            heading={card.heading}
            text={card.text}
            isReversed={index % 2 !== 0} // Reverse layout for the second card (index 1)
          >
            {card.text} {/* Passing the text content as children */}
          </Text>
        ))}
        <Pagination
          prevLink="/career"
          prevText="Career Counseling"
          nextLink="/dbt"
          nextText="Dialectical Behavior Therapy (DBT)"
        />
        <BrowseButton />
        <ArticleCards cards={cards} />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default SpeechTherapy;
