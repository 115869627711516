import React from "react";
import Header from "./Comp/Header";
import TopNav from "./TopNav";
import Footer from "./Footer";
import img0 from "../assets/flowers-crop-4.webp";
import img1 from "../assets/who_wmhd_high_1920px.tmb-1200v.jpg";
import img2 from "../assets/preview-page.jpg";
import img3 from "../assets/shutterstock_1212356686.webp";
import img4 from "../assets/metaphor-memory_23-2151240836.jpg";
import img5 from "../assets/artwork-vincent-van.jpg";
import img6 from "../assets/hand-drawn-ptsd.jpg";
import img7 from "../assets/6.jpg";
import img8 from "../assets/depositphotos_95793324-stock-photo.jpg";
import img9 from "../assets/mental-health-awareness.jpg";
import img10 from "../assets/images (1).jpg";
import img11 from "../assets/world-suicide-prevention.jpg";
import img12 from "../assets/167-1679675_psychedelic-therapy-negative.png";
import InfoSection from "./Comp/InfoSection";

function Events() {
  const cards = [
    {
      image: img1,
      label: "World Mental Health Day",
      link: "/mental-health-day",
    },
    {
      image: img2,
      label: "Mental Illness Awareness Week",
      link: "/mental-illness-awareness",
    },
    {
      image: img3,
      label: "Eating Disorders Awareness Week",
      link: "/eating-disorders-awareness",
    },
    {
      image: img4,
      label: "Schizophrenia Awareness",
      link: "/schizophrenia-awareness",
    },
    {
      image: img5,
      label: "World Bipolar Day",
      link: "/bipolar-awareness",
    },
    {
      image: img6,
      label: "National PTSD Awareness Day",
      link: "/ptsd-awareness",
    },
    {
      image: img7,
      label: "Children’s Mental Health Awareness Week",
      link: "/child-awareness",
    },
    {
      image: img8,
      label: "International Day of Happiness",
      link: "/happiness-day-awareness",
    },
    {
      image: img9,
      label: "Mental Health Awareness Week",
      link: "/mental-health-awareness",
    },
    {
      image: img10,
      label: "World Suicide Prevention Day",
      link: "/world-suicide-awareness",
    },
    {
      image: img11,
      label: "National Suicide Prevention Week",
      link: "/national-suicide-prevention",
    },
    {
      image: img12,
      label: "National Mental Health Awareness Month",
      link: "/national-mental-health-awareness",
    },
  ];
  return (
    <>
      <TopNav />
      <main>
        <Header leftTitle="Upcoming Events" breadcrumbTitle="Events" />
        <section>
          <div className="flex flex-row-reverse items-center justify-center space-x-4 relative -my-16 md:-my-4 lg:-my-0">
            {/* Image on the left, fixed in place */}
            <div className="relative w-full max-w-2xl top-0 left-0 z-0">
              <img
                src={img0}
                alt="Background"
                className="w-full h-auto rounded-lg object-cover"
              />
            </div>

            {/* Text in the center */}
            <div className="w-full lg:w-1/3 text-center relative z-10 mt-20 pl-5">
              <h2 className="text-lg md:text-2xl lg:text-3xl py-1 text-[#4db953] font-sans font rounded-lg px-2 w-full md:w-3/4 mb-2">
                Global Mental Health Events
              </h2>
              <p className="text-sm md:text-lg font-light text-gray-600 w-full md:w-3/4">
                Join Us in Celebrating Global Efforts to Promote Mental Health
                and Well-Being
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="min-h-screen flex items-center justify-center py-12 mb-12 p-6 mt-12 xl:mt-0">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-12 max-w-6xl">
              {cards.map((card, index) => (
                <a
                  key={index}
                  href={card.link}
                  className="block bg-white rounded-lg shadow-lg hover:shadow-[#e0ebe4] transition ease-in-out duration-300 "
                >
                  {/* Wrapper for image with overflow-hidden */}
                  <div className="overflow-hidden rounded-t-lg">
                    <img
                      src={card.image}
                      alt={card.label}
                      className="w-full h-56 object-cover transition-transform duration-300 transform hover:scale-110"
                    />
                  </div>
                  <div className="p-4 text-center">
                    <p className="font-semibold">{card.label}</p>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </section>

        <InfoSection />
      </main>
      <Footer />
    </>
  );
}

export default Events;
